<template>

  <overlay-content ref="oc1" id="oc-legal-notice">
    <h2>Impressum</h2>
    <div class="blue-title">Herausgeber</div>
    Deutsche Gesellschaft für Internistische Intensivmedizin und Notfallmedizin e.V. (DGIIN)<br>
    Seumestraße 8,<br>
    10245 Berlin<br>
    Vertreten durch den Präsidenten
    <br><br>
    <b>Kontakt</b><br>
    Fachgruppe COVRIIN<br>
    E-Mail: <a href="mailto:covriin@rki.de">covriin@rki.de</a>
    <br><br>
    <div class="blue-title">Hosting</div>
    Deutsche Gesellschaft für Internistische Intensivmedizin und Notfallmedizin (DGIIN)<br>
    <a href="https://www.dgiin.de/">https://www.dgiin.de/</a>
    <br><br>
    <div class="blue-title">Konzeption, Design, Entwicklung</div>
    Deutsche Gesellschaft für Internistische Intensivmedizin und Notfallmedizin (DGIIN) <a href="https://www.dgiin.de/">https://www.dgiin.de/</a><br>
    Deutsche Gesellschaft für Infektiologie (DGI) <a href="https://www.dgi-net.de/">https://www.dgi-net.de/</a><br>
    Deutsche Gesellschaft für Pneumologie (DGP) <a
      href="https://www.pneumologie.de/">https://www.pneumologie.de/</a><br>
    Deutsche Interdisziplinäre Vereinigung für Intensiv- und Notfallmedizin e.V. <a href="https://www.divi.de/">https://www.divi.de/</a><br>
    Fachgruppe COVRIIN <a href="https://www.rki.de/covriin/">https://www.rki.de/covriin/</a>
    <br><br>
    <u>Webentwicklung</u>
    <div style="display:flex;flex-direction:row;">
      <div style="width:24px;margin-right:8px;"><img src="@/assets/ob_wave.png" alt=""></div>
      <a title="oceanbyte.de" href="https://oceanbyte.de" target="_blank">oceanbyte.de</a></div>
    <br>
    <div class="blue-title">Copyright</div>
    Bilder, Grafiken und Texte sind urheberrechtlich geschützt und dürfen ohne Absprache nicht anderweitig verwendet
    werden. Der Quellcode ist unter der GPLv3 lizenziert (außer jQuery).
  </overlay-content>

  <overlay-content ref="oc2" id="oc-terms-of-use">
    <h2>Nutzungsbedingungen</h2>
    <b>Rechtliche Hinweise zur Haftung:</b>
    <br>Die Autoren des Therapieleitfadens „COVID-19-Therapieempfehlungen: Interaktive Orientierungshilfe für Ärztinnen
    und Ärzte“ sind um Richtigkeit und Aktualität der Informationen bemüht, die in die Empfehlungen und
    Entscheidungsalgorithmen einfließen. Die Grundlage stellen hier die durch die federführenden Fachgesellschaften
    Deutsche Gesellschaft für internistische Intensivmedizin und Notfallmedizin (DGIIN), Deutsche Interdisziplinäre
    Vereinigung für Intensiv- und Notfallmedizin e.V. (DIVI), Deutsche Gesellschaft für Pneumologie und Beatmungsmedizin
    e.V. (DGP) und Deutsche Gesellschaft für Infektiologie e.V. (DGI) erstellte „S3-Leitlinie – Empfehlungen zur
    stationären Therapie von Patienten mit COVID-19“. Die Erstellung der entsprechenden Evidenzsynthese sowie die
    Verabschiedung der Empfehlungen erfolgt gemäß Methodik und Konsensverfahren der Nationalen Versorgungsleitlinien.
    Zusätzlich fließt in die Empfehlungen die fachliche Expertise der Fachgruppe COVRIIN.
    <br><br>Die Autoren behalten es sich vor, Teile des Informationsangebots oder das gesamte Angebot ohne besondere
    Ankündigung zu verändern oder zu löschen. Das Angebot gibt nur einen Stand der sich stets weiter entwickelnden
    Diskussion zur COVID-Behandlung wieder.
    <br><br>Dieser Therapieleitfaden ist lediglich eine Anregung und keine Vorgabe. Er kann keine individuellen
    klinischen Situationen abbilden und daher die individualisierte Hinzuziehung weiterer Informationen und die
    Entscheidung der Ärztinnen und Ärzte nicht ersetzen. Die Autoren übernehmen daher keine Gewähr für die Aktualität,
    Richtigkeit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Für Schäden materieller oder
    immaterieller Art, die durch Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung
    fehlerhafter oder unvollständiger Informationen unmittelbar oder mittelbar verursacht werden, haften die Autoren
    nicht, sofern ihr nicht nachweislich vorsätzliches oder grob fahrlässiges Verschulden zur Last gelegt werden kann.
    <br><br>Für alle Inhalte von Seiten anderer Anbieter, auf die Nutzer des Therapieleitfadens durch einen "link"
    weitergeleitet werden, können die Autoren keinerlei Haftung übernehmen. Für den Inhalt der verlinkten Seiten sind
    ausschließlich deren Betreiber verantwortlich.
    <br><br>
    Stand: 20.05.2022
  </overlay-content>

  <overlay-content ref="oc3" id="oc-disclaimer">
    <h2>Disclaimer</h2>
    Der Therapieleitfaden „COVID-19-Therapieempfehlungen: Interaktive Orientierungshilfe für Ärztinnen und Ärzte“ stellt
    eine Synthese der aktuellen S3-Leitlinien zur stationären Therapie von COVID-19-Patienten
    (<a
      href="https://www.awmf.org/uploads/tx_szleitlinien/113-001LGl_S3_Empfehlungen-zur-stationaeren-Therapie-von-Patienten-mit-COVID-19_2022-03.pdf"
      target="_blank">113-001LGl_S3_Empfehlungen-zur-stationaeren-Therapie-von-Patienten-mit-COVID-19_2022-03.pdf
    (awmf.org)</a>)
    und der aktuellen praktischen Hinweise und Handlungsempfehlungen der Fachgruppe COVRIIN
    (<a href="https://www.rki.de/covriin" target="_blank">www.rki.de/covriin</a>)
    dar. Durch den interaktiven Charakter wird für die behandelnden Ärztinnen und Ärzte eine individualisierte
    Zusammenstellung der empfohlenen Therapie ermöglicht, entsprechend der Erkrankungsphase, dem Ausmaß der
    respiratorischen Unterstützung, den individuellen Risikofaktoren sowie dem Impfstatus der zu behandelnden
    Patientinnen und Patienten als Hilfestellung zur Therapieentscheidung. Dieser Therapieleitfaden ersetzt nicht die
    Beurteilung der/des individuellen Patientin oder Patienten durch eine Ärztin oder einen Arzt. Durch regelmäßige,
    auch kurzfristige Aktualisierungen werden die bestehenden Empfehlungen zeitnah an neue Erkenntnisse angepasst und
    dynamisch fortentwickelt. Dieser Therapieleitfaden sollte daher auch nur in der aktuellsten Fassung Anwendung
    finden.
    <br><br>
    Stand: 23.05.2022
  </overlay-content>

  <overlay-content ref="oc4" id="oc-conflict-of-interest">
    <h2>Interessenkonflikte</h2>
    Die „COVID-19-Therapieempfehlungen: Interaktive Orientierungshilfe für Ärztinnen und Ärzte“ sind als
    Kooperationsprojekt der federführenden Fachgesellschaften der S3-Leitlinie zur stationären Behandlung von COVID-19
    und der Fachgruppe COVRIIN entstanden.
    <br><br>
    Folgende Personen (in alphabetischer Reihenfolge) waren an der Konzeption und Umsetzung des Projektes beteiligt
    (inkl. Interessenkonflikte):
    <br><br>
    <b>Prof. Dr. med. Uwe Janssens:</b> Mitgliedschaften: Past Präsident Deutsche Interdisziplinäre Vereinigung für
    Intensiv- und Notfallmedizin (DIVI), Generalsekretär Deutsche Gesellschaft für Internistische Intensivmedizin und
    Notfallmedizin (DGIIN). Keine weiteren Interessenskonflikte.
    <br><br>
    <b>Dr. med. Björn-Erik Ole Jensen:</b> Mitgliedschaften: Fachgruppe COVRIIN, Ständiger Arbeitskreis der Kompetenz-
    und Behandlungszentren für Krankheiten durch hochpathogene Erreger (STAKOB), Deutsche Gesellschaft für Infektiologie
    (DGI), Paul-Ehrlich-Gesellschaft (PEG), Deutsche AIDS-Gesellschaft (DAIG), European AIDS Clinical Society (EACS),
    International AIDS Society (IAS). Interessenkonflikte: Mitarbeit in einem Wissenschaftlichen Beirat (ViiV
    Healthcare, Gilead Sciences, Theratechnologies), Bezahlte Vortrags-/oder Schulungstätigkeit (ViiV Healthcare, GSK,
    Gilead Sciences, Janssen-Cilag, BMS, Boehringer, Abbvie, MSD, Pfizer, Falk Foundation, Fresenius Medical Care),
    Forschungsvorhaben/ Durchführung klinischer Studien (ViiV, Gilead, Roche, Janssen-Cilag, BMS, Boehringer)
    <br><br>
    <b>Prof. Dr. med. Christian Karagiannidis:</b> Mitgliedschaften: Präsident Deutsche Gesellschaft für Internistische
    Intensivmedizin und Notfallmedizin (DGIIN), Med.-Wiss. Leiter der Deutschen Interdisziplinären Vereinigung für
    Intensiv- und Notfallmedizin (DIVI), Deutsche Gesellschaft für Pneumologie (DGP), ExpertInnenrat Corona
    Bundesregierung, Fachgruppe COVRIIN. Keine weiteren
    Interessenkonflikte.
    <br><br>
    <b>Prof. Dr. Stefan Kluge:</b> Mitgliedschaften: Vorstandsmitglied Deutsche Gesellschaft für Internistische
    Intensivmedizin und Notfallmedizin (DGIIN), Präsidiums der Deutschen Interdisziplinären Vereinigung für Intensiv-
    und Notfallmedizin (DIVI), Deutsche Gesellschaft für Pneumologie (DGP). Interessenkonflikte: Tätigkeit als Berater
    und/oder Gutachter (Fresenius, Gilead Sciences, MSD, Pfizer), Mitarbeit in einem Wissenschaftlichen Beirat
    (Fresenius, Gilead Sciences, MSD, Pfizer), Bezahlte Vortrags-/oder Schulungstätigkeit (Astra, Biotest, Cytosorbents,
    Daiichi, Sankyo, Fresenius, Gilead Sciences, Mitsubishi, Tanabe, Pharma, Philips, Zoll), Forschungs-vorhaben/
    Durchführung klinischer Studien (Cytosorbents, Daiichi, Sankyo)
    <br><br>
    <b>Dr. med. Jakob J. Malin:</b> Mitgliedschaften: Deutsche Gesellschaft für Infektiologie (DGI), Deutsche
    Gesellschaft für Innere Medizin (DGIM). Interessenkonflikte: Tätigkeit als Berater und/oder Gutachter (MAPLE Health
    Group), Mitarbeit in einem Wissenschaftlichen Beirat (Gilead Sciences, Atriva, Therapeutics), Forschungsvorhaben/
    Durchführung klinischer Studien (NIH, CUREVac, Janssen-Cilag)
    <br><br>
    <b>PD Dr. med. Christoph Spinner:</b> Mitgliedschaften: Deutsche AIDS-Gesellschaft (DAIG), European AIDS Clinical
    Society (EACS), International AIDS Society (IAS). Interessenkonflikte: Tätigkeit als Berater und/oder Gutachter
    (BMBF), Mitarbeit in einem Wissenschaftlichen Beirat (AbbVie, Astra Zeneca, BBraun, Gilead, Eli Lilly, Formycon,
    MSD, Molecular partners, Roche, SOBI, Janssen-Cilag, GSK, ViiV Healthcare), Bezahlte Vortrags-/oder
    Schulungstätigkeit (AbbVie, Astra Zeneca, B Braun, BioNtech, Gilead, Eli Lilly, Formycon, MSD, Roche, Janssen-Cilag,
    GSK/ViiV Healthcare), Bezahlte Autorenschaften (AbbVIe), Forschungsvorhaben/ Durchführung klinischer Studien
    (AbbVie, Apeiron, B Braun, Gilead, Eli Lilly, MSD, Roche, SOBI, Janssen-Cilag, GSK/ViiV Healthcare)
    <br><br>
    <b>Dr. med. Miriam Stegemann:</b> Mitgliedschaften: Deutsche Gesellschaft für Infektiologie (DGI), Deutsche
    Gesellschaft für Tropenmedizin, Reisemedizin und Globale Gesundheit (DTG), Deutsche Gesellschaft für Innere Medizin
    (DGIM), European Society of Clinical Microbiology and Infectious Diseases (ESCMID). Interessenkonflikte: Bezahlte
    Vortrags-/oder Schulungstätigkeit (Akademie für Infektionsmedizin).
  </overlay-content>

</template>

<script>
import OverlayContent from "@/components/structures/OverlayContent";

export default {
  name: "FooterOverlays",
  components: {OverlayContent}
}
</script>

<style scoped>
.blue-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #005aa0;
}
</style>