<template>
  <div class="header-container">
    <div class="title-text">
      <div>COVID-19-Therapieempfehlungen: Archiv</div>
    </div>
    <div class="title-logo">
      <header-logo/>
    </div>
  </div>
</template>

<script>
import HeaderLogo from "@/components/header/HeaderLogo";

export default {
  name: "TheHeader",
  components: {HeaderLogo}
}
</script>

<style scoped>
.header-container {
  padding: 1.2em 2em 0.7em 2em;
  display: flex;
  flex-direction: column;
}

.title-text {
  color: #005baa;
  font-size: 1.7em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.2em;
  padding: 0 2em;
}

.title-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


@media screen and (max-width: 800px) {
  .title-text {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 750px) {
  .header-container {
    padding: 0.8em 1em;
  }
}

@media screen and (max-width: 500px) {
  .header-container {
    padding: 0.5em 0.5em;
  }
}

</style>