<template>
  <div class="page-container border">
    <h2>Aktuelle Version</h2>
    <div class="current-version-container">

      <default-button @on-click="visit('https://www.dgiin.de/covriin/index.html#/')" arrow-right :style="'btn-dark'"
                      arrow-pos-top="35%">
        <div class="text-larger-2">Version 3.1</div>
        <div class="text-smaller-1">vom 23.11.2023</div>
      </default-button>

    </div>
    <h2>Ältere Versionen</h2>
    <h3>Version 3</h3>
    <div class="version-container">
      <default-button @on-click="visit('/version/3.0/')" arrow-right :style="'btn-light'" arrow-pos-top="35%">
        <div class="text-larger-2">Version 3.0</div>
        <div class="text-smaller-1">vom 30.03.2023</div>
      </default-button>
    </div>
    <h3>Version 2</h3>
    <div class="version-container">
      <default-button @on-click="visit('/version/2.2/')" arrow-right :style="'btn-light'" arrow-pos-top="35%">
        <div class="text-larger-2">Version 2.2</div>
        <div class="text-smaller-1">vom 07.11.2022</div>
      </default-button>
      <default-button @on-click="visit('/version/2.1/')" arrow-right :style="'btn-light'" arrow-pos-top="35%">
        <div class="text-larger-2">Version 2.1</div>
        <div class="text-smaller-1">vom 24.10.2022</div>
      </default-button>
      <default-button @on-click="visit('/version/2.0/')" arrow-right :style="'btn-light'" arrow-pos-top="35%">
        <div class="text-larger-2">Version 2.0</div>
        <div class="text-smaller-1">vom 29.09.2022</div>
      </default-button>
    </div>
    <h3>Version 1</h3>
    <div class="version-container">
      <default-button @on-click="visit('/version/1.0/')" arrow-right :style="'btn-light'" arrow-pos-top="35%">
        <div class="text-larger-2">Version 1.0</div>
        <div class="text-smaller-1">vom 23.05.2022</div>
      </default-button>
    </div>
  </div>
</template>

<script>

import DefaultButton from "@/components/controls/DefaultButton";

export default {
  name: 'HomeView',
  components: {DefaultButton},
  methods: {
    visit(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style>
h2 {
  margin: 0;
}

h3 {
  margin: 1em 0 0 0;
  font-size: 1.3em;
}

.page-container .default-btn {
  max-width: 20em;
}

.page-container .current-version-container .default-btn {
  margin: 0 auto;
}

.current-version-container {
  padding: 2em;
  font-size: 1.2em;
}

.version-container {
  padding-top: 1em;
}

.version-container > div {
  margin-bottom: 1em;
}

</style>