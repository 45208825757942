<template>
  <div :id="id"
       :class="`default-btn ${style} ${(maxWidth ? 'default-btn-max-width' : '')} ${(background ? 'default-hover' : '')} ${(isArrowRotated ? 'arrow-rotate' : '')}`">
    <div v-show="!isHidden" @click="onClick()" v-if="arrowLeft" class="arrow arrow-left"
         :style="`${(arrowPosTop ? 'top:' + arrowPosTop + ';' : '')}`"></div>
    <div v-show="!isHidden" @click="onClick()" v-if="arrowRight" class="arrow arrow-right"
         :style="`${(arrowPosTop ? 'top:' + arrowPosTop + ';' : '')}`"></div>
    <button v-show="!isHidden" @click="onClick()" v-bind:type="type" v-bind:title="title"
            :style="`${(arrowLeft ? 'padding-left: 2.2em;' : '')} ${(arrowRight ? 'padding-right: 2.2em;' : '')} ${(background ? 'background-color:' + background +';' : '')} ${(color ? 'color:' + color +';' : '')} ${(css ? css : '')}`">
      <slot v-if="!html">Button</slot>
      <span v-if="html" v-html="html"></span>
    </button>
    <div v-if="hint" v-show="!isHidden" class="btn-hint">
      <div class="hint-arrow"></div>
      <div class="hint-text">{{ hint }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultButton",
  props: {
    title: String,
    html: String,
    css: String,
    id: String,
    background: String,
    color: String,
    hint: String,
    arrowLeft: {
      type: Boolean,
      default: false
    },
    arrowRight: {
      type: Boolean,
      default: false
    },
    arrowRotate: {
      type: Boolean,
      default: false
    },
    arrowPosTop: String,
    type: {
      type: String,
      default: "button"
    },
    style: {
      type: String,
      default: "btn-dark"
    },
    maxWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isArrowRotated: false,
      isHidden: false
    }
  },
  methods: {
    onClick() {
      this.$emit('on-click')
      if (this.arrowRotate) {
        this.isArrowRotated = !this.isArrowRotated
      }
    }
  }
}
</script>

<style scoped>
.default-btn {
  position: relative;
}

.default-btn button {
  width: 100%;
  padding: 0.75em 1.6em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 1em;
  border: none;
  border-radius: 0.8em;
  transition: 300ms ease-in-out;
}

.default-btn-max-width button {
  width: 100%;
}

.default-btn.default-hover button:hover,
.default-btn.default-hover .arrow:hover + button {
  background-color: #005baa !important;
}

.default-btn button:active {
  box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, .75);
}

/* btn-light */
.default-btn.btn-light button {
  background-color: #e2eaf6;
  color: #444;
}

.default-btn.btn-light button:hover,
.default-btn.btn-light .arrow:hover + button {
  background-color: #BBCBE3;
  color: #222;
}

/* btn-dark */
.default-btn.btn-dark button {
  background-color: #005baa;
  color: #fff;
}

.default-btn.btn-dark button:hover,
.default-btn.btn-dark .arrow:hover + button {
  background-color: #00447a;
}

.default-btn.btn-dark .arrow {
  color: #fff;
}

/* btn-red-light */
.default-btn.btn-red-light button {
  background-color: #fff;
  color: #EC143F;
  border: #EC143F 3px solid;
}

.default-btn.btn-red-light button:hover,
.default-btn.btn-red-light .arrow:hover + button {
  background-color: #F5BCC7;
}

.default-btn.btn-red-light .arrow {
  color: #EC143F;
}

/* btn-green-light */
.default-btn.btn-green-light button {
  background-color: #fff;
  color: #0FB063;
  border: #0FB063 3px solid;
}

.default-btn.btn-green-light button:hover,
.default-btn.btn-green-light .arrow:hover + button {
  background-color: #A3D4B5;
}

.default-btn.btn-green-light .arrow {
  color: #0FB063;
}

/* btn-orange-light */
.default-btn.btn-orange-light button {
  background-color: #fff;
  color: #fdb216;
  border: #fdb216 3px solid;
}

.default-btn.btn-orange-light button:hover,
.default-btn.btn-orange-light .arrow:hover + button {
  background-color: #FFDC93;
}

.default-btn.btn-orange-light .arrow {
  color: #fdb216;
}

/* btn-blue-light */
.default-btn.btn-blue-light button {
  background-color: #fff;
  color: #005baa;
  border: #005baa 3px solid;
}

.default-btn.btn-blue-light button:hover,
.default-btn.btn-blue-light .arrow:hover + button {
  background-color: #e2eaf6;
}

.default-btn.btn-blue-light .arrow {
  color: #005baa;
}

.arrow {
  top: 29%;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0.6em solid transparent;
  border-bottom: 0.6em solid transparent;
  padding: 0;
  cursor: pointer;
}

.arrow-left {
  left: 0.8em;
  border-right: 0.7em solid;
  transition: 300ms ease-in-out;
}

.arrow-right {
  right: 0.8em;
  border-left: 0.7em solid;
  transition: 300ms ease-in-out;
}

.arrow-rotate .arrow-right {
  transform: rotate(90deg);
}

.arrow-rotate .arrow-left {
  transform: rotate(-90deg);
}

.default-btn:hover > .btn-hint {
  opacity: 1;
  visibility: visible;
}

.btn-hint {
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  position: relative;
  transition: 300ms ease-in-out;
}

.btn-hint > .hint-text {
  position: absolute;
  left: 30%;
  bottom: 3.3em;
  color: #fff;
  background-color: #005baa;
  max-width: 20em;
  padding: 1em;
  border: #fff solid 2px;
  border-radius: 0.8em;
  box-shadow: 0 0 28px -12px rgba(0, 0, 0, 0.75);
}

.hint-arrow {
  position: absolute;
  bottom: 2.2em;
  left: 40%;
  border-top: 1.2em solid;
  width: 0;
  height: 0;
  border-left: 1.2em solid transparent;
  border-right: 1.2em solid transparent;
  color: #005baa;
}

@media screen and (max-width: 900px) {
  .btn-hint {
    font-size: 0.9em;
    font-style: italic;
  }
}

</style>