<template>
  <div class="container">
    <header>
      <the-header/>
    </header>
    <div class="content">
      <router-view/>
    </div>
    <footer>
      <the-footer/>
    </footer>
  </div>
</template>

<script>
import "./assets/style.css"
import TheFooter from "@/components/TheFooter";
import TheHeader from "@/components/TheHeader";

export default {
  components: {TheHeader, TheFooter}
}
</script>

<style>
.container {
  max-width: 50%;
  min-width: 1000px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-box-shadow: 0 0 28px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 28px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 28px -12px rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 1000px) {
  .container {
    max-width: 100%;
    min-width: auto;
  }
}
</style>
