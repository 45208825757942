<template>
  <div class="header-logo">
    <div class="logo-container">
      <a href="https://www.dgiin.de/" target="_blank"><img alt="" src="@/assets/logos/logo_dgiin.png"></a>
      <a href="https://www.dgi-net.de/" target="_blank"><img alt="" src="@/assets/logos/logo_dgi.png"></a>
      <a href="https://www.pneumologie.de/" target="_blank"><img alt="" src="@/assets/logos/logo_dgp.png"></a>
      <a href="https://www.divi.de/" target="_blank"><img alt="" src="@/assets/logos/logo_divi.png"></a>
      <a href="https://www.rki.de/DE/Content/Kommissionen/COVRIIN/FG_COVRIIN_node.html" target="_blank">
        <img alt="" src="@/assets/logos/logo_covriin.png">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderLogo"
}
</script>

<style scoped>
.header-logo {
  margin: 0 0.5em;
  max-width: 520px;
}

.header-logo svg {
  max-height: 50px;
  font-size: 16px;
}

.logo-container {
  display: flex;
  flex-direction: row;
}

</style>