<template>
  <div class="footer-container">
    <div class="footer-bar">
      <div class="lnk" @click="showOverlayFooter('oc1')">Impressum</div>
      <!--
      <div class="lnk" @click="showOverlayFooter('oc2')">Nutzungsbedingungen</div>
      <div class="lnk" @click="showOverlayFooter('oc3')">Disclaimer</div>
      <div class="lnk" @click="showOverlayFooter('oc4')">Interessenkonflikte</div>
      -->
    </div>
  </div>

  <footer-overlays ref="footer-overlays"></footer-overlays>

</template>

<script>
import OverlayContent from "@/components/structures/OverlayContent";
import FooterOverlays from "@/components/footer/FooterOverlays";

export default {
  name: "TheFooter",
  components: {FooterOverlays, OverlayContent},
  methods: {
    showOverlayFooter(ref) {
      this.$refs["footer-overlays"].$refs[ref].show()
    }
  }
}
</script>

<style scoped>
.footer-container {
  padding: 1em;
}

.footer-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-bar > div {
  margin: 0 0.8em;
}

.footer-container .default-btn {
  font-size: 1.25em;
}

@media screen and (max-width: 750px) {
  .footer-container {
    padding: 1em 1em;
  }

  .footer-bar {
    flex-direction: column;
    align-items: center;
  }

  .footer-bar > div {
    margin-top: 0.2em;
  }
}

</style>