<template>
  <div :id="`oc-${id}`" class="default-overlay default-overlay-content">
    <div>
      <div class="window-close">
        <button @click="hide">×</button>
      </div>
      <div class="window-content">
        <div v-if="html" v-html="html"></div>
        <slot v-if="!html"><i>No content</i></slot>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "../controls/DefaultButton";

export default {
  name: "OverlayContent",
  components: {DefaultButton},
  props: {
    id: String,
    html: String
  },
  methods: {
    hide() {
      $(".default-overlay-content#oc-" + this.id).removeClass("show")
    },
    show() {
      $(".default-overlay-content#oc-" + this.id).addClass("show")
    }
  }
}
</script>

<style scoped>

.default-overlay-content > div {
  margin: 6em auto 0;
  width: 45%;
}

.window-close {
  position: absolute;
  right: 1em;
}

.window-close button {
  margin: 0;
  padding: 0.1em 0.41em;
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background-color: #005aa0;
  color: white;
  transition: 300ms ease-in-out;
  position: relative;
}

.window-close button:hover {
  background-color: #00447a;
}

.window-close button:active {
  box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, .75);
}

.window-content {
  overflow: auto;
  padding: 1.5em;
}


@media screen and (max-width: 1300px) {
  .default-overlay-content > div {
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .default-overlay-content > div {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .default-overlay-content {
    background-color: #fff;
    width: 100%;
    height: 100vh;
    margin: 0;
  }

  .window-close button {
    font-size: 2.4em;
  }

  .default-overlay-content > div {
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    margin: 0;
  }

}

</style>